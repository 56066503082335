import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useVideoStateContext } from "../context/videoContext";
import StartImage from "../components/StartImage";
import "react-toastify/dist/ReactToastify.css";
import HeartBeatComp from "../components/HeartBeatComp";
import VideoComp from "../components/videoComp";
import TopLayerComp from "../components/TopLayerComp";
import ImageOrVideoComp from "../components/ImageOrVideoComp";
const ProjectVideos = ({ data }) => {
  const { id } = useParams();
  const {
    keyIndex,
    initDatas,
    getCurrentVideoFromIntroOrLoopVideos,
    startPlayingVideos,
    currentVideo,
    defaultVideo,
    stopMicrophone,
    introVideos,
    activateMicrophone,
    handleCanListen,
    areAllVideosDownloaded,
    isChoosenVideoFinishingPlaying,
    handleDataInConsole,
    horizontalAdjustment,
    verticalAdjustment,
    checkConnectify,
  } = useVideoStateContext();
  const [startImage, setStartImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [topLayerVideo, setTopLayerVideo] = useState(null);

  const [startAutomatically, setStartAutomatically] = useState(false);
  const [showDefaultVideo, setShowDefaultVideo] = useState(false);
  const [isFirstart, setIsFirstart] = useState(true);

  const videoRef = useRef(null);

  let defVideoRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === "f") {
      getCurrentVideoFromIntroOrLoopVideos(true);
    } else if (event.key === "g") {
      handleCanListen(true);
      if (currentVideo.isLoopVideo) {
        activateMicrophone(true);
      }
    } else if (event.key === "h") {
      if (!currentVideo.isLoopVideo) {
        handleCanListen(false);
      }

      stopMicrophone();
    }
  };

  function checkUrl() {
    let url = new URL(window.location.href);
    if (url.searchParams.has("log")) {
      handleDataInConsole();
    }
    if (url.searchParams.has("start") || url.searchParams.has("triggerMic")) {
      getCurrentVideoFromIntroOrLoopVideos(true);
      setStartAutomatically(true);
      setShowDefaultVideo(false);
    }
  }

  useEffect(() => {
    if (data.startImage) setStartImage(data.startImage);
    if (data.backgroundImage) setBackgroundImage(data.backgroundImage);
    if (data.topLayer) {
      setTopLayerVideo(
        `${process.env.REACT_APP_BASE_URL.replace("api", "videos")}/${
          data.topLayer
        }`
      );
    }
    checkUrl();
    initDatas(data, id, data.llmModelName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nodata() {}

  useEffect(
    () => {
      checkConnectify();
      if (videoRef.current.src) {
        const video = videoRef.current;

        video.load();

        video.onloadedmetadata = () => {
          const duration = video.duration;
          if (currentVideo.isLoopVideo) {
            setShowDefaultVideo(true);
            const defVideo = defVideoRef.current;
            defVideo.src = currentVideo.videoUrl;
            if (document.visibilityState !== "hidden") {
              defVideo.play();
            }
            defVideo.onended = () => {
              defVideo.current = null;
            };
            if (isFirstart && introVideos.length === 0) {
              activateMicrophone(true);
              setIsFirstart(false);
            }
            return;
          } else {
            setShowDefaultVideo(true);
          }
          video.onplaying = () => {
            setShowDefaultVideo(false);
            setStartAutomatically(false);

            const intervalId = setInterval(() => {
              if (video.currentTime >= duration - 0.3) {
                setShowDefaultVideo(true);
              }
            }, 300);
            video.onpause = video.onended = () => {
              isChoosenVideoFinishingPlaying();
              videoRef.current = null;
              video.current = null;
              clearInterval(intervalId);
            };
          };
        };
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVideo]
  );

  return areAllVideosDownloaded ? (
    <div className="text-3xl">
      <h1>data are loading</h1>
    </div>
  ) : (
    <div
      tabIndex="0"
      onKeyDown={handleKeyPress}
      className="fullscreen relative overflow-hidden"
    >
      <HeartBeatComp className="fullscreen" />
      {startImage && startPlayingVideos === false ? (
        <StartImage base64Image={startImage} />
      ) : (
        <div className="fullscreen">
          {backgroundImage && startPlayingVideos ? (
            <ImageOrVideoComp url={backgroundImage} />
          ) : (
            <></>
          )}
          <div
            className={`fullscreen ${
              !showDefaultVideo && !startAutomatically ? "invisible" : "visible"
            } `}
          >
            <VideoComp
              videoRef={null}
              classname={`fullscreen`}
              index={null}
              onEnd={
                startAutomatically
                  ? getCurrentVideoFromIntroOrLoopVideos
                  : nodata
              }
              srcUrl={defaultVideo.videoUrl}
              autoPlay={true}
              mute={true}
              horizontalAdjustment={horizontalAdjustment}
              verticalAdjustment={verticalAdjustment}
            />

            <VideoComp
              videoRef={defVideoRef}
              classname={`fullscreen`}
              index={null}
              onEnd={null}
              srcUrl={""}
              autoPlay={true}
              mute={true}
              horizontalAdjustment={horizontalAdjustment}
              verticalAdjustment={verticalAdjustment}
            />
          </div>
        </div>
      )}
      <VideoComp
        videoRef={videoRef}
        classname={`fullscreen ${
          (startImage && !startPlayingVideos) || currentVideo.isLoopVideo
            ? "invisible"
            : "visible"
        } `}
        index={keyIndex}
        onEnd={getCurrentVideoFromIntroOrLoopVideos}
        srcUrl={currentVideo.videoUrl}
        autoPlay={true}
        mute={false}
        horizontalAdjustment={horizontalAdjustment}
        verticalAdjustment={verticalAdjustment}
      />
      {startPlayingVideos && topLayerVideo ? (
   
          <TopLayerComp url={topLayerVideo} />
    
      ): 
        <></>
      }

      {!currentVideo.html || currentVideo.html === "" ? (
        <></>
      ) : (
        <div className="absolute bottom-1/2 left-0 bg-slate-500 bg-transparent-100 max-h-full max-w-96 text-white m-5">
          <div className=" text-white m-5">{currentVideo.html}</div>
        </div>
      )}
      {startPlayingVideos ? (
        <></>
      ) : (
        <button
          className="absolute top-10 right-10 bg-blue-500 text-white px-10 py-1 rounded "
          onClick={getCurrentVideoFromIntroOrLoopVideos}
        >
          Start
        </button>
      )}
    </div>
  );
};
export default ProjectVideos;

/*
 */
